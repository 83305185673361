@font-face {
	font-family: 'ProximaNovaRegular';
	font-display: fallback;
	unicode-range: U+000-5FF;
	src: local('ProximaNovaRegular'),
		url(../fonts/proximanova-regular-webfont.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNovaSemibold';
	font-display: fallback;
	unicode-range: U+000-5FF;
	src: local('ProximaNovaSemibold'),
		url(../fonts/proximanova-semibold-webfont.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNovaBold';
	font-display: fallback;
	unicode-range: U+000-5FF;
	src: local('ProximaNovaBold'),
		url(../fonts/proximanova-bold-webfont.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'UnpaktHelvetica';
	font-display: fallback;
	unicode-range: U+000-5FF;
	src: local('UnpaktHelvetica'),
		url(../fonts/helvetica-unpakt.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'UnpaktHelveticaBold';
	font-display: fallback;
	unicode-range: U+000-5FF;
	src: local('UnpaktHelveticaBold'),
		url(../fonts/neue-helvetica-bold.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}

.anchor-button {
	font-size: 100%;
	font-family: inherit;
	border: 0;
	padding: 0;
	background: none;
}

.desktop-only-white {
	background: white !important;
}

.hidden {
	display: none;
}

/*Utility*/

.unpakt-blue {
	color: #00bee8 !important;
}

.unpakt-orange {
	color: #f67c15 !important;
}

.unpakt-gray {
	color: #869095 !important;
}

/*Modals*/

.center-modal {
	overflow: visible !important;
	width: auto;
	max-width: 960px !important;
}

@media only screen and (max-width: 767) {
	.modal-body {
		width: 100vw !important;
		padding: 0 15px !important;
		height: calc(100vh - 200px) !important;
	}
}

/* Modal Repeats */

.top-action-panel {
	display: flex;
	justify-content: flex-end;
}

.bottom-action-panel {
	font-size: 15px;
}

.font-proxima {
	font-family: ProximaNovaRegular, sans-serif !important;
}

.font-proxima-semi {
	font-family: ProximaNovaSemibold, sans-serif !important;
}

.font-proxima-bold {
	font-family: ProximaNovaBold, sans-serif !important;
}

.font-unpakt-helvetica {
	font-family: UnpaktHelvetica, sans-serif !important;
}

.font-unpakt-helvetica-bold {
	font-family: UnpaktHelveticaBold, sans-serif !important;
}

.antialiased {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon {
	color: #042b3b;
	display: inline-block;
	width: 1.25rem;
	height: 1.25rem;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
	vertical-align: middle;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.icon-question-mark {
	width: 1.015625em;
}

.field-error {
	border: 1px solid red !important;
	background: rgba(255, 0, 0, 0.1) !important;
	color: red !important;
}

.field-error-v3 {
	border: 2px solid red !important;
	background: #f4f4f4 !important;
	/* color: red !important; */
}

.field-error-message.message,
.field-success-message.message {
	font-size: 0.75rem;
	display: block;
	padding-top: 5px;
	clear: both;
}

.field-error-message {
	color: red;
}

.field-success-message {
	color: green;
}

.custom-error {
	color: red;
	margin-top: -1rem;
	font-size: 0.8rem;
	margin-bottom: 0.5rem;
}

.ladda-button.typicals.gray-button {
	display: block;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #042b3b;
	background: #fdfdfd;
	/* background: -webkit-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%); */
	background: -o-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%);
	background: linear-gradient(to bottom, #fdfdfd 27%, #dbdbdb 100%);
	border: 1px solid rgba(5, 43, 59, 0.27);
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(26, 24, 24, 0.08);
	width: 200px;
	margin: 0 auto;
	height: 40px;
	padding: 6px 10px;
	font-size: 13px;
	line-height: 28px;
	justify-content: space-between;
	display: flex;
	cursor: pointer;
	text-transform: capitalize;
}

.gray-button {
	display: block;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #042b3b;
	background: #fdfdfd;
	/* background: -webkit-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%); */
	background: -o-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%);
	background: linear-gradient(to bottom, #fdfdfd 27%, #dbdbdb 100%);
	border: 1px solid rgba(5, 43, 59, 0.27);
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(26, 24, 24, 0.08);
	width: 200px;
	margin: 0 auto;
	height: 40px;
	padding: 6px 10px;
	font-size: 13px;
	line-height: 28px;
	justify-content: space-between;
	display: flex;
	cursor: pointer;
	text-transform: capitalize;
}

.btn-hollow {
	min-width: 135px;
	padding: 0;
	height: 30px;
	line-height: 30px;
	display: inline-block;
	text-align: center;
	border: 1px solid #fff;
	border-radius: 3px;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 0.8rem;
	background-color: #f0f0f0;
}

.btn-color-orange {
	border-color: #f7801e;
	color: #f7801e !important;
}

.btn-color-orange:hover {
	background-color: #f7801e;
	color: white !important;
}

.flat-button {
	text-transform: capitalize;
	padding: 9px 10px;
	background-color: #f7801e;
	border-radius: 2px;
	color: #fff;
	text-shadow: 0 1px 1px #f5f5f5;
	text-rendering: optimizelegibility;
}

/* NEW BUTTONS AND LINKS PER THE STYLE GUIDE  */

.btn-small {
	vertical-align: middle;
	text-align: center;
	height: 34px;
	min-width: 65px;
	border-radius: 3px;
	cursor: pointer;
	padding: 5px 0;
	font-family: ProximaNovaRegular, sans-serif;
	font-size: 13px;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

.btn-primary {
	background: #f57c14;
	color: white !important;
	border: 1px solid #df7804;
}

.btn-large {
	vertical-align: middle;
	text-align: center;
	padding: 8px 30px;
	border-radius: 3px;
	font-size: 18px;
	cursor: pointer;
	font-family: ProximaNovaRegular, sans-serif;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

.primary {
	background: #f57c14;
	color: white !important;
	border: 1px solid #df7804;
}

.secondary {
	background: #f2f2f2;
	color: #0d2836 !important;
	border: 1px solid #bababa;
}

.primary-btn-small {
	vertical-align: middle;
	text-align: center;
	padding: 4px 30px;
	background: #f57c14;
	color: white !important;
	border: 1px solid #df7804;
	border-radius: 3px;
	cursor: pointer;
	font-family: ProximaNovaRegular, sans-serif;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	white-space: nowrap;
	text-transform: capitalize;
}

.leads-blurb {
	font-weight: 300;
	letter-spacing: 0.11px;
	line-height: 25px;
	text-align: center;
	font-size: 18px;
}

@media screen and (max-width: 992px) {
	.leads-dialog {
		max-height: 540px;
		max-width: 800px;
	}
}

@media screen and (min-width: 993px) {
	.leads-dialog {
		height: 540px;
		width: 800px;
	}
}
.leads-dialog {
	max-height: 540px;
	max-width: 800px;
}

.leads-title {
	font-size: 36px;
	padding-top: 16px;
	padding-bottom: 16px;
	margin: 0 6px 0 0;
	line-height: 24px;
	font-family: 'ProximaNovaBold';
	text-align: center;
}

.leads {
	text-align: center;
}

.leads-truck-div {
	width: 65px;
	height: 41px;
}

.leads-txt {
	width: 100%;
	padding-top: 10px;
	height: 50px;
	max-width: 400px;
	font-size: 18px;
	color: #042b3b;
	background: #e7e7e7;
	border: 1px solid white;
	padding: 8px 14px;
	box-shadow: none;
	font-family: 'ProximaNovaRegular', sans-serif;
	margin: 0 0 15px 0;
}

.leads-btn {
	width: 100%;
	vertical-align: middle;
	align-items: center !important;
	text-align: center;
	padding: 4px 30px;
	background: #000000;
	color: white !important;
	border: 1px solid #000000;
	cursor: pointer;
	font-family: ProximaNovaRegular, sans-serif;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	white-space: nowrap;
	height: 50px;
	max-width: 400px;
	text-transform: capitalize;
	font-size: 18px;
}

.header-container {
	position: fixed;
	top: 0;
	width: 100%;
	height: 70px;
	line-height: 70px;
	/* -webkit-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.26);
	box-shadow: 0 4px 3px rgba(0, 0, 0, 0.26); */
	z-index: 9999;
	background: #1a3749;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#1a3749),
		color-stop(13%, #1a3548),
		color-stop(14%, #1b364b),
		color-stop(42%, #193447),
		color-stop(44%, #1a354a),
		color-stop(47%, #183346),
		color-stop(55%, #193248),
		color-stop(58%, #193344),
		color-stop(59%, #1a3347),
		color-stop(60%, #183145),
		to(#193344)
	);
	background: -webkit-linear-gradient(
		top,
		#1a3749 0,
		#1a3548 13%,
		#1b364b 14%,
		#193447 42%,
		#1a354a 44%,
		#183346 47%,
		#193248 55%,
		#193344 58%,
		#1a3347 59%,
		#183145 60%,
		#193344 100%
	);
	background: -o-linear-gradient(
		top,
		#1a3749 0,
		#1a3548 13%,
		#1b364b 14%,
		#193447 42%,
		#1a354a 44%,
		#183346 47%,
		#193248 55%,
		#193344 58%,
		#1a3347 59%,
		#183145 60%,
		#193344 100%
	);
	background: linear-gradient(
		to bottom,
		#1a3749 0,
		#1a3548 13%,
		#1b364b 14%,
		#193447 42%,
		#1a354a 44%,
		#183346 47%,
		#193248 55%,
		#193344 58%,
		#1a3347 59%,
		#183145 60%,
		#193344 100%
	);
}
.header-container .logo-container {
	padding-left: 25px;
	display: block;
	height: 100%;
	margin-right: 20px;
	float: left;
}
.header-container .logo-container .logo {
	height: 100%;
}
.header-container .mobile-navbar {
	position: fixed;
	top: 70px;
	height: calc(100% - 70px);
	width: 75%;
	max-width: 480px;
	color: #fff;
	overflow: auto;
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: -webkit-transform 0.3s ease-in-out;
	transition: -webkit-transform 0.3s ease-in-out;
	-o-transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	z-index: 1;
}
.header-container .hamburger {
	height: 100%;
	right: 25px;
	position: absolute;
	width: 35px;
	height: 70px;
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	-o-transition: transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
}
.header-container .hamburger .bar {
	position: absolute;
	top: 44%;
	height: 4px;
	background: #fff;
	-webkit-transition: background 0s 0.2s;
	-o-transition: background 0s 0.2s;
	transition: background 0s 0.2s;
}
.header-container .hamburger .bar:before {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: #fff;
	content: '';
	top: -9px;
	-webkit-transition: top 0.2s 0.2s, -webkit-transform 0.2s 0s;
	transition: top 0.2s 0.2s, -webkit-transform 0.2s 0s;
	-o-transition: top 0.2s 0.2s, transform 0.2s 0s;
	transition: top 0.2s 0.2s, transform 0.2s 0s;
	transition: top 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
}
.header-container .hamburger .bar:after {
	bottom: -9px;
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: #fff;
	content: '';
	-webkit-transition: bottom 0.2s 0.2s, -webkit-transform 0.2s 0s;
	transition: bottom 0.2s 0.2s, -webkit-transform 0.2s 0s;
	-o-transition: bottom 0.2s 0.2s, transform 0.2s 0s;
	transition: bottom 0.2s 0.2s, transform 0.2s 0s;
	transition: bottom 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
}

.primary-btn-small:hover,
.primary-btn-small:active {
	background: #fe931b;
}

.primary-btn-large {
	vertical-align: middle;
	text-align: center;
	padding: 8px 30px;
	background: #f57c14;
	color: white !important;
	border: 1px solid #df7804;
	border-radius: 3px;
	font-size: 18px;
	cursor: pointer;
	font-family: ProximaNovaRegular, sans-serif;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
}

.inventory-update-button > div > svg {
	vertical-align: top;
}

.primary-btn-large:hover,
.primary-btn-large:active {
	background: #fe931b;
}

.secondary-btn-small {
	vertical-align: middle;
	text-align: center;
	padding: 0 30px;
	background: #f2f2f2;
	color: #0d2836 !important;
	border: 1px solid #bababa;
	border-radius: 3px;
	cursor: pointer;
	font-family: ProximaNovaRegular, sans-serif;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	text-transform: capitalize;
	height: 34px;
}

.secondary-btn-small:hover,
.secondary-btn-small:active,
.gray-button:hover,
.gray-button:active {
	background: #fafafa !important;
	border: 1px solid #b4b4b4;
}

.unstyled-button {
	background: none;
	border: none;
}

.in-line-link {
	color: #f57c14;
}

.in-line-link:hover,
.in-line-link:active {
	border-bottom: 1px solid #f57c14;
	color: #f57c14;
}

.read-more {
	text-decoration: none;
	cursor: pointer;
	color: #f57c14;
	text-transform: uppercase;
	font-size: 0.8rem;
	border-bottom: 1px solid transparent;
}

.read-more:hover,
.read-more:active {
	border-bottom: 1px solid #f57c14;
	color: #f57c14;
}

.read-more:after {
	content: '➝';
	padding-left: 0.8em;
	vertical-align: middle;
}

.inventory-page-icon-small {
	width: 40px;
	height: 40px;
}

@media screen and (max-width: 992px) {
	.inventory-large-block {
		display: none;
	}
	.item-custom .item-image {
		background: none;
		height: 10px;
	}
	.inventory-small-block {
		min-height: 40px;
	}
}
@media screen and (min-width: 993px) {
	.inventory-small-block {
		display: none !important;
	}
}

.item-image {
	background: url('/assets/images/inventory/custom-item.png') no-repeat center
		30px;
	height: 110px;
	width: 100%;
}

.item-square .item-details .title {
	font-size: 0.8em;
	color: #042b3b;
	line-height: 0.8em;
}

.items-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	background: white;
	margin: 0;
}

.item-desc {
	font-size: 11px;
	color: #737677;
}

.room-title {
	font-family: 'ProximaNovaSemibold', sans-serif;
	font-size: 15px;
	padding: 10px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	color: white;
	text-align: center;
	background-color: #003854;
	border-radius: 3px;
}

.item-custom {
	height: 260px;
	padding: 15px 15px 15px 15px;
	border-width: 0 1px 1px 0;
	border-style: solid;
	border-color: #dddddd;
	position: relative;
	background-color: #f2f4f4;
}

.item-details {
	padding-bottom: 10px;
}

.item-square {
	padding: 10px;
	position: relative;
	height: auto;
	border-radius: 5px;
	margin: 5px;
	border: 1px solid #d6d6d6;
	box-shadow: 0 0 5px #0000000d;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
@media screen and (max-width: 992px) {
	.item-details {
		padding-bottom: 0px;
		padding-top: 5px;
	}
	.item-square {
		padding: 0px 10px;
		position: relative;
		height: auto;
		border-radius: 0px;
		margin: 0px;
		border: 1px solid #d6d6d6;
		box-shadow: 0 0 5px #0000000d;
		min-height: 40px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.room-title {
		font-family: 'ProximaNovaSemibold', sans-serif;
		font-size: 15px;
		padding: 10px;
		border: 1px solid #ddd;
		color: #36383a;
		text-align: center;
		background-color: #fbfafa;
		border-radius: 3px;
		margin-left: 0px;
		margin-right: 0px;
	}
}
.icons-container {
	padding: 0;
	margin: 0;
	height: 20px;
	position: absolute;
	top: 7px;
	right: 10px;
	display: flex;
}

.plus-minus-holder {
	display: flex;
	margin: 0;
	padding: 0;
	justify-content: space-between;
	width: 100%;
	text-align: right;
	align-items: center;
}

.result {
	font-size: 24px;
	font-weight: 800;
	width: 50%;
	font-family: 'UnpaktHelveticaBold', sans-serif;
	font-size: 1.5em;
	line-height: 30px;
}

.desc {
	font-size: 0.75rem;
	color: #737677;
	line-height: 16px;
	min-height: 16px;
}

.uppercase {
	text-transform: uppercase;
}

.link {
	color: #f7801e;
	cursor: pointer;
	border-bottom: 1px solid transparent;
}
.link:hover {
	border-bottom: 1px solid #f7801e;
	color: #f7801e;
}

/* funnel repeats */

.funnel-center-wrapper {
	width: 100%;
}

@media only screen and (max-width: 992px) {
	.funnel-center-wrapper {
		padding-top: 55px;
		border-bottom: 55px solid #1a3649;
		background-color: #fff;
	}
}

.funnel-title {
	font-size: 23px;
	padding-top: 0;
	margin: 0 6px 0 0;
	line-height: 24px;
	font-family: 'UnpaktHelvetica', sans-serif;
}

.funnel-subtitle {
	font-size: 1em;
	margin-bottom: 0;
	font-family: 'UnpaktHelvetica', sans-serif;
}

@media only screen and (min-width: 81.3125em) {
	.funnel-title {
		font-size: 30px;
	}
}

.cta-button {
	font-size: 24px;
	font-family: 'UnpaktHelvetica', sans-serif;
	display: block;
	text-align: center;
	border: 1px solid #bc5300;
	cursor: pointer;
	text-transform: uppercase;
	border-radius: 2px;
}

.filled {
	color: #fff;
	padding: 9px 10px 6px 10px;
	text-shadow: 0 1px 1px #e67200;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: linear-gradient(to bottom, #f59400 0%, #f17000 100%);
}

.submit {
	padding: 6px 1.5em;
	vertical-align: top;
	line-height: 1em;
}

.cta-button.small {
	font-family: 'ProximaNovaRegular', sans-serif;
	font-size: 13px;
	line-height: 14px;
	text-shadow: none;
	-webkit-font-smoothing: inherit;
}

.cta-button:hover {
	color: white;
	background: linear-gradient(to bottom, #f59c00 0, #f37f00 100%);
	text-shadow: none !important;
}

/* forms */

.field-title {
	height: auto;
	font-family: 'ProximaNovaBold', sans-serif;
	font-weight: 800;
	font-size: 16px;
	line-height: 16px;
	text-transform: capitalize;
	padding-bottom: 8px;
	margin: 0;
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-left: -5px;
}

h4.field-title {
	padding-top: 15px;
}

.columns {
	padding: 0 30px;
	margin: 0 30px;
}

.selection {
	background: #ffffff;
	border: 1px solid #d6d6d6;
	border-radius: 3px;
	padding: 0 25px 0 20px;
	line-height: 34px;
	height: 34px;
	cursor: pointer;
	overflow: hidden;
	font-size: 13px;
}

.home-selector {
	border-radius: 0px !important;
}

.selection:active,
.selection:focus {
	border: 1px solid #37beeb !important;
}

.unpakt-field,
.unpakt-textbox,
.unpakt-textarea {
	height: 34px;
	width: 250px;
	font-size: 13px;
	color: #042b3b;
	background: #fff;
	border: 1px solid #819ca1;
	padding: 8px 14px;
	box-shadow: none;
	border-radius: 2px;
	font-family: 'ProximaNovaRegular', sans-serif;
	margin: 0 0 15px 0;
}

.unpakt-field:hover,
.unpakt-textbox:hover,
.unpakt-textarea:hover,
.unpakt-field:focus,
.unpakt-textbox:focus,
.unpakt-textarea:focus {
	border-color: #042b3b;
}

.consultation-date {
	width: inherit;
	height: 34px;
	font-size: 13px;
	color: #042b3b;
	background: #fff;
	border: 1px solid #819ca1 !important;
	padding: 8px 14px;
	box-shadow: none;
	border-radius: 2px;
	font-family: 'ProximaNovaRegular', sans-serif;
	margin: 0 0 15px 0;
}

.city,
.address {
	width: 65%;
	margin-right: 2.5%;
}

.zip {
	width: 37.5%;
	margin-right: 2.5%;
}

.location-form .row.fields {
	border-bottom: 1px solid #d6d6d6;
	background: #fff;
}

.location-form .row:last-child {
	border: none;
}

.location-form .apt,
.location-form .state {
	width: 32.5%;
	height: 34px;
}

.form-row {
	margin: 6px;
	padding: 0;
	display: flex;
	justify-content: space-between;
}

.unpakt-checkbox-container input[type='checkbox'] {
	margin: 1px 12px 0 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: white;
	cursor: pointer;
	border-radius: 0;
	border: solid 1px #9eafb4;
	padding: 7px;
	height: 15px;
}

.plan-ladda {
	margin-left: auto;
	margin-bottom: 5px;
}

.unpakt-checkbox-container input[type='checkbox']:hover {
	border: solid 1px #042b3b;
}

.unpakt-checkbox-container input[type='checkbox']:checked {
	background: #fff url('/assets/images/blue_checkmark.svg') no-repeat center
		center;
	border: 1px solid #cacaca;
}

.unpakt-checkbox-container {
	display: flex;
	align-items: center;
	font-size: 0.875rem;
}
.disabled {
	color: #ababab;
	border-color: #ababab;
	background: #f0f0f0;
}
.unpakt-textarea {
	height: 85px;
	line-height: 18px;
	padding: 10px 14px;
	resize: none;
}

.auth-error {
	text-align: center;
	color: red;
	font-size: 0.9rem;
	padding: 10px;
	font-weight: 100;
}

/*
  BRAINTREE CREDIT CARD STYLING */

#card-number,
#cvv,
#expiration-month,
#expiration-year,
#postal-code {
	height: 34px;
	font-size: 13px;
	color: #042b3b;
	background: #fff;
	border: 1px solid #819ca1;
	padding: 8px 14px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 2px;
	font-family: 'ProximaNovaRegular', sans-serif;
	margin: 0 15px 0 0;
}

#card-number {
	width: 250px;
}

#card-number.braintree-hosted-fields-focused,
#cvv.braintree-hosted-fields-focused,
#expiration-month.braintree-hosted-fields-focused,
#expiration-year.braintree-hosted-fields-focused,
#postal-code.braintree-hosted-fields-focused {
	border-color: #777;
}

#card-number.braintree-hosted-fields-focused,
#cvv.braintree-hosted-fields-focused,
#expiration-month.braintree-hosted-fields-focused,
#expiration-year.braintree-hosted-fields-focused,
#postal-code.braintree-hosted-fields-focused {
	border-color: tomato;
}

#card-number.braintree-hosted-fields-focused,
#cvv.braintree-hosted-fields-focused,
#expiration-month.braintree-hosted-fields-focused,
#expiration-year.braintree-hosted-fields-focused,
#postal-code.braintree-hosted-fields-focused {
	border-color: limegreen;
}

.react-datepicker__navigation--next {
	border-left-color: #f98049 !important;
}

.react-datepicker__navigation--previous {
	border-right-color: #f98049 !important;
}

.react-datepicker__header {
	background-color: white !important;
	border-bottom: none !important;
}

.react-datepicker__month {
	border: solid 1px #999999;
}

.react-datepicker-popper {
	z-index: 100 !important;
}

/* Plan page repeats */

.section-header {
	padding-bottom: 10px;
}
.plan-section .section-title {
	display: inline-block;
	margin: 0;
	font-family: 'ProximaNovaBold', sans-serif;
	font-size: 20px;
}
.plan-section {
	padding-bottom: 50px;
}
.focused-input {
	border-color: #00bee8 !important;
	border-width: 2px !important;
}
.item-column {
	font-size: 13px;
	float: none !important;
	height: 46px;
	padding: 5px 15px;
	border-bottom: 1px solid #dbdbdb;
}

.item-price {
	font-size: 13px;
	font-family: 'UnpaktHelveticaBold', sans-serif;
}

.item-small {
	width: 35%;
	text-align: right;
	text-transform: uppercase;
}

.plan-page .section-content {
	background: #eff1f3;
	border: 1px solid #dbdbdb;
	padding: 20px;
}

.plan-page p {
	padding: 0;
	margin: 0;
	border: none;
	outline: none;
}

.plan-page .funnel-title {
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	font-size: 1.8rem;
	line-height: inherit;
}

.two-columns-table .item-big {
	width: 65%;
	border-right: 1px solid #dbdbdb;
}

.two-columns-table {
	border-width: 1px 1px 0 1px;
	border-style: solid;
	border-color: #dbdbdb;
	margin-bottom: 0;
}

/* Popover defaults */

.popover {
	box-sizing: border-box;
	border: solid 1px #d2d2d2 !important;
	border-radius: 3px !important;
	background-color: #fff !important;
	padding: 0.5rem 0.8rem !important;
	max-width: 20rem;
	font-size: 0.875rem;
	box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
	z-index: 69420 !important;
}

.ns-popover-tooltip p {
	line-height: 1.25;
	font-size: 0.75rem;
}

.ns-popover-tooltip p:last-of-type {
	margin-bottom: 0;
}

.tooltip-content .actions {
	margin-top: 16px;
}

.tooltip-content .action {
	font-size: 0.9em;
	width: 100%;
}

.tooltip-close {
	right: 0;
	text-align: right;
}
@media screen and (min-width: 757px) {
	.special-offer-image {
		margin-top: 15px;
	}

	.tooltip-close {
		display: none;
	}
}

.close-reveal-modal {
	display: inline-block;
	position: static;
	width: 40px;
	font-size: 26px;
	line-height: 50px;
	color: #042b3b;
	text-align: center;
	top: 0.625rem;
	right: 1.375rem;
	cursor: pointer;
	font-weight: bold;
	border: none;
	background: transparent;
}

.bottom-action-panel {
	padding: 10px 15px;
	background: #fff;
	display: flex;
	justify-content: space-between;
	box-shadow: 0 -3px 4px -4px rgba(0, 0, 0, 0.3);
}

.section-content {
	padding: 0;
	max-width: 62.5rem;
	margin: 0;
	width: 100%;
	-webkit-font-smoothing: antialiased;
}

.section-title {
	font-family: UnpaktHelveticaBold, sans-serif;
	font-size: 27px;
	line-height: 1;
	display: inline-block;
	padding-bottom: 8px;
	letter-spacing: -0.025em;
	margin-bottom: 15px;
}

.nf-section {
	padding: 25px 0.9375rem;
	margin: 0;
}

.arrival-date {
	width: 100%;
	height: 50px;
	padding-left: 37px;
}
.long-distance-delivery-date {
	height: 34px;
	width: 100%;
	border: 1px solid #819ca1;
}

.sit-datepicker-input {
	height: 34px;
	border: 1px solid #819ca1;
	width: 170px;
	padding-top: 7px;
}

.react-datepicker__triangle {
	display: none !important;
}

.react-datepicker__input-container {
	width: inherit;
}

/* Hey Karl, you really shouldn't set global styles for our datepicker. */

.react-datepicker__input-container > input {
	border: 1px solid #d6d6d6;
	border-radius: 3px;
}
.compare-datepicker {
	padding: 8px 14px !important;
	vertical-align: middle;
	width: 100%;
}

.react-datepicker__input-container > input {
	cursor: pointer;
	padding-top: 5px;
	padding-left: 40px;
	font-family: ProximaNovaRegular, sans-serif;
	font-weight: 200;
	caret-color: transparent;
}

.unpakt-datepicker.home input {
	height: 65px;
}

.unpakt-datepicker.home.v9 input {
	height: 65px;
	padding-left: 45px;
	border-radius: 0px;
}

.unpakt-datepicker.home svg {
	height: 60px;
}

.react-datepicker__input-container > .long-distance-delivery-date {
	border: 1px solid #819ca1;
}

.react-datepicker-wrapper {
	width: 100%;
}

input[type='date'] {
	appearance: none !important;
	-webkit-appearance: none !important;
}

.unpakt-radio input[type='radio'] {
	margin: 0 12px 0 0;
	padding: 7px;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff;
	cursor: pointer;
	border-radius: 100%;
	border: 1px solid #a0afb4;
}

.unpakt-radio input[type='radio']:hover {
	border: 1px solid black;
}

.unpakt-radio input[type='radio']:checked {
	background: url('/assets/images/radio-checked.png') -2px -2px no-repeat;
	border: 1px solid #2bbdec;
	font-family: ProximaNovaBold, sans-serif !important;
}

.unpakt-radio-container .selected {
	font-family: ProximaNovaBold, sans-serif;
}

.unpakt-radio-container {
	display: block;
	padding-left: 23px;
	cursor: pointer;
	position: relative;
	line-height: 18px;
	font-size: 14px;
}

.unpakt-radio-container input {
	position: absolute;
	left: 0;
}

.unpakt-radio label span {
	font-family: ProximaNovaRegular, sans-serif;
	font-size: 0.875rem;
	display: block;
	color: #91a4aa;
}

.continue-link {
	color: #f7801e;
	text-decoration: underline;
	font-size: 18px;
	line-height: 1.25;
	cursor: pointer;
}
.replaceify input {
	width: 100%;
	min-height: 50px;
	color: #042b3b;
	border: 1px solid #d6d6d6;
	border-radius: 0px;
	padding: 10.5px 14px 10.5px 47px;
	font-size: 1rem;
	font-family: ProximaNovaRegular, sans-serif;
	font-weight: 200;
}
.replaceify.home input {
	min-height: 65px;
}
.replaceify > svg {
	position: absolute;
	width: 32px;
	height: 44px;
	left: 4px;
}
.replaceify.home svg {
	min-height: 59px;
}

@media (max-width: 992px) {
	.replaceify.home input {
		min-height: 50px;
	}
	.replaceify.home svg {
		min-height: 45px;
	}
	.unpakt-datepicker.home.v9 input {
		padding-left: 47px;
		height: 50px;
	}
}

.icon.from-arrow svg {
	fill: #042b3b;
}

.icon.to-arrow svg {
	fill: #042b3b;
}

.icon.to-arrow svg {
	fill: #042b3b;
	height: 10px;
}

.geosuggest__suggests {
	position: relative;
}

.geosuggest__suggests--hidden {
	max-height: 0;
	overflow: hidden;
	border-width: 0 !important;
	padding: 0 !important;
}

.suggestions-dropdown {
	position: absolute;
	width: 100%;
	top: 50px;
	z-index: 5;
	background: #fff;
	border: solid #829ca1;
	color: #042b3b;
	border-width: 0 1px 1px;
	border-radius: 0 0 2px 2px;
	-webkit-transition: opacity 0.2s ease-in;
	-o-transition: opacity 0.2s ease-in;
	transition: opacity 0.2s ease-in;
	cursor: pointer;
	margin-bottom: 0;
	padding-bottom: 25px;
}

.suggestions-list {
	list-style: none;
	margin: 0;
}

.suggestions-item {
	position: relative;
	-webkit-transition: background 0.2s ease-in-out;
	-o-transition: background 0.2s ease-in-out;
	transition: background 0.2s ease-in-out;
	padding: 8px;
	font-size: 13px;
	line-height: 18px;
	border-bottom: 1px solid #e9e9e9;
	text-decoration: none;
	text-indent: 15px;
}

.suggestions-item::before {
	fill: #91a4aa;
}

.suggestions-item:hover {
	background-color: #e2e3e5;
}

.suggestions-item::before {
	content: '';
	display: block;
	width: 3px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	text-transform: none;
	-webkit-transition: background-color 0.25s;
	-o-transition: background-color 0.25s;
	transition: background-color 0.25s;
}

.suggestions-item::after {
	content: '';
	display: block;
	position: absolute;
	top: 9px;
	left: 5px;
	height: 15px;
	filter: brightness(6) hue-rotate(-70deg) saturate(0);
	width: 15px;
	background: url('/assets/images/ic_LocationAdd.svg');
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.suggestions-item:hover::after {
	filter: brightness(4) hue-rotate(190deg) saturate(0.9);
}

.suggestions-item:hover::before {
	background-color: #f7801e;
}

.icon.blank-pin {
	fill: #91a4aa;
	vertical-align: middle;
	margin-right: 4px;
	-webkit-transition: color 0.25s;
	-o-transition: color 0.25s;
	transition: color 0.25s;
	width: 12px;
}

.suggestions-item:hover svg {
	fill: #f7801e;
}

.geosuggest__item--active {
	background-color: #e2e3e5;
}

.geosuggest__item--active::after {
	filter: brightness(4) hue-rotate(190deg) saturate(0.9);
}

.geosuggest__item--active::before {
	background-color: #f7801e;
}

.geosuggest__item--active svg {
	fill: #f7801e;
}

.geosuggest ul::after {
	display: block;
	content: url(/assets/images/powered_by_google_on_white.png);
	position: absolute;
	left: -1px;
	width: calc(100% + 2px);
	height: 27px;
	text-align: end;
	padding: 4px 10px;
	background: white;
	border: 1px solid #829ca1;
	border-top: none;
}

.marketing-page h2,
.standard-text h2 {
	font-size: 2.8rem;
	font-weight: 700;
	font-family: ProximaNovaBold, sans-serif;
}
.marketing-page h3,
.standard-text h3 {
	font-size: 1.7rem;
	font-family: 'ProximaNovaBold', sans-serif;
}
.marketing-page h4,
.standard-text h4 {
	font-size: 1.5rem;
	font-family: 'ProximaNovaBold', sans-serif;
}
.marketing-page h5,
.standard-text h5 {
	font-size: 1.4rem;
	font-family: 'ProximaNovaBold', sans-serif;
}

.marketing-page.mobile h2 {
	font-size: 2rem;
	line-height: 2rem;
}
.marketing-page.mobile h3 {
	font-size: 1.2rem;
}
.marketing-page.mobile h4 {
	font-size: 1.2rem;
}
.marketing-page.mobile h5 {
	font-size: 1.2rem;
}

.icon-link > a {
	font-size: 12px;
	color: #337a8c !important;
	text-decoration: none;
	border-bottom: 1px dotted #337a8c;
}
.icon-link > a:hover {
	color: #2bbdec !important;
}
.sidebar-section {
	padding: 18px 8px;
}
.sidebar-toggle-arrow {
	font-size: 0.75rem;
	margin: auto 0;
	line-height: 2;
	cursor: pointer;
}

.compare-items-list > li:first-of-type > div {
	margin-top: 0 !important;
}
.route-section-header > a {
	height: 21px;
	display: inline;
	overflow: hidden;
	vertical-align: top;
	color: #f7801e !important;
	font-size: 14px;
	text-decoration: underline;
}
.no-style-button {
	appearance: none;
	-webkit-appearance: none;
	border: none;
	box-shadow: none;
	background: inherit;
	all: unset;
}

.flat-table {
	border-width: 1px 0 0 1px;
	border-style: solid;
	border-color: #dbdbdb;
	width: 100%;
	min-width: 640px;
}
.table-titles {
	font-family: 'ProximaNovaBold', sans-serif;
	font-size: 13px;
	height: 40px;
	vertical-align: middle;
	text-transform: uppercase;
	color: #042b3b;
	background: #eff1f3;
	border: 0 solid #dbdbdb;
	border-width: 1px 1px 1px 0;
}
.table-title {
	border-width: 0 1px 1px 0;
	border-style: solid;
	border-color: #dbdbdb;
	padding: 1rem;
	min-width: 165px;
	min-height: 40px;
	text-align: left;
	border-left: 1px solid #dbdbdb;
	font-size: 0.8125rem;
	line-height: 1.25;
	font-family: 'ProximaNovaBold', sans-serif;
}
.print-margins {
	margin: 50px;
}
@media print {
	.section-content {
		background-color: white !important;
	}
	.payment-protection {
		color: black !important;
	}
	.payment-protection:before {
		width: 0 !important;
	}
	.no-print,
	.edit-button,
	.profile-link,
	.plan-action-container,
	.lead,
	.small-map,
	i,
	.my-inventory-holder > button,
	.moving-insurance {
		display: none !important;
	}
	.support-number,
	.data {
		text-decoration: none !important;
	}
	.company-link {
		border: none !important;
	}
	thead th,
	thead td,
	tbody td {
		height: auto;
		padding: 12px;
	}
	.item-image {
		display: inline-block;
		padding: 0;
		margin: 0;
		vertical-align: middle;
		background: none;
		width: auto;
		height: auto;
	}
	.box-image {
		background: none;
		background-size: cover;
		display: inline-block;
		padding: 0;
		vertical-align: middle;
		width: auto;
		height: auto !important;
	}
	.item-title {
		display: inline-block;
		vertical-align: middle;
	}
	.item-desc {
		font-size: 9px;
		color: #8f8f8f;
	}
	.num {
		font-size: 13px;
	}
	.my-inventory-table th,
	td {
		display: table-cell !important;
	}
	.room-name {
		background-color: white !important;
		color: black !important;
	}
	.inventory-breakdown-icon,
	.box-image-breakdown {
		width: 0 !important;
		background: none !important;
	}

	.item-text {
		left: 20px !important;
		top: 15px;
	}
	.printed-on {
		display: block !important;
		margin-bottom: 20px;
	}
	.item-details > .info {
		margin-left: 20px;
	}
	.suggested {
		background: white !important;
		color: white !important;
	}
	input[type='radio']:checked {
		filter: gray !important;
		-webkit-filter: grayscale(1) !important;
		filter: grayscale(1) !important;
		border-color: black !important;
	}
	a {
		color: black !important;
		text-decoration: none !important;
	}
}

.printed-on {
	display: none;
}

.ladda > svg {
	vertical-align: top !important;
}

.text-red {
	color: red !important;
}
