/******* NORMALIZATION *******/
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}
html {
	height: 100%;
	font-family: 'ProximaNovaRegular', sans-serif;
	font-display: fallback;
	font-style: normal;
	font-weight: normal;
	-webkit-text-size-adjust: 100%;
	font-size: 100%;
	/* Font varient */
	font-variant-ligatures: none;
	-webkit-font-variant-ligatures: none;
	/* Smoothing */
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
body {
	background: #fff;
	color: #042b3b;
	cursor: auto;
	line-height: 1;
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 100%;
	font-size: 100%;
	letter-spacing: 0.5px;
	box-sizing: border-box;
}
:focus {
	outline-style: auto;
	outline-width: 0px !important;
}

ul {
	list-style: none;
	padding-left: 0;
}

h1 {
	color: #042b3b !important;
}

strong,
b {
	font-family: ProximaNovaBold, sans-serif;
	font-weight: 400 !important;
}

a {
	color: #f57c14;
	transition: color 0.2s ease-in-out;
}

svg {
	vertical-align: middle;
}

a:hover {
	text-decoration: none !important;
}

input:focus,
input:active {
	outline-style: none;
	outline-width: 0px;
	border: 1px solid #37beeb !important;
}

/* variables*/
:root {
	--footer-font-size: 1rem;
	--color-primary: #00beeb;
	--color-primary-dark: #08072b;
	--color-primary-light-dark: #122a3a;
	--color-accent: #ff7600;
	--color-accent-dark: #ff7600;
	--color-accent-light: #f79643;
	--color-gray: #707070;
	--color-white: #fff;
	--color-divider: #dbdada;
}
